import React, {useEffect} from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import moment from "moment";
import "moment/locale/pt";
import {Link, useHistory} from "react-router-dom";

import "./styles.scss";
import {fullURL, get, post} from "../../YoubizProvider";
import {useFormulario} from "../../components/Formularios/Provider";
import {confirmAlert} from "react-confirm-alert";
import cogoToast from "cogo-toast";

moment.locale("pt");

const PedidosCartoes = () => {
    let [produtos, setProdutos] = React.useState(null);
    let [pedidos, setPedidos] = React.useState(null);
    let [selecionados, setSelecionados] = React.useState([]);
    let [ContextSelecionados] = React.useState(React.createContext({selecionados: []}));

    const formulario = useFormulario();
    const history = useHistory();

    useEffect(() => {
        get("/cartoes/produtos/listar?numero_resultados=5000").then(res => {
            let aux = {};
            res.resultados.forEach(produto => {
                aux[produto.id] = produto;
            });
            setProdutos(aux);
        })
    }, []);

    if (!produtos)
        return null;

    let columns = [
        {
            headerStyle: {
                width: 30,
                paddingRight: 0
            },
            text: <div className="form-check m-0">
                <label className="form-check-label">
                    <ContextSelecionados.Consumer>
                        {({selecionados}) => {
                            let ativo = selecionados.length && pedidos.resultados.filter(p => !selecionados.includes(p.id)).length === 0;
                            return <input type="checkbox" checked={ativo} onChange={() => {
                                if (ativo) {
                                    setSelecionados(selecionados.filter(s => !pedidos.resultados.map(p => p.id).includes(s)));
                                } else {
                                    setSelecionados([...selecionados, ...pedidos.resultados.map(p => p.id)]);
                                }
                            }}/>
                        }}
                    </ContextSelecionados.Consumer>
                    <i className="input-helper"/>
                </label>
            </div>,
            formatter(_, pedido) {
                return <div className="form-check m-0">
                    <label className="form-check-label">
                        <ContextSelecionados.Consumer>
                            {({selecionados}) => {
                                return <input type="checkbox" checked={selecionados.includes(pedido.id)} onChange={() => {
                                    if (selecionados.includes(pedido.id)) {
                                        setSelecionados(selecionados.filter(s => s !== pedido.id));
                                    } else {
                                        setSelecionados([...selecionados, pedido.id]);
                                    }
                                }
                                }/>
                            }}
                        </ContextSelecionados.Consumer>
                        <i className="input-helper"/>
                    </label>
                </div>;
            }
        }, {
            dataField: "vendedor_numero_colaborador",
            text: 'Id'
        }, {
            text: 'Colaborador',
            formatter(_, pedido) {
                if (!pedido.vendedor)
                    return null;
                return <Link to={`/colaborador/${pedido.vendedor_id}`} className={"primary-color underline m-0 text-nowrap"}>{pedido.vendedor_nome}</Link>
            }
        }, {
            dataField: "produtos_pedido",
            text: 'Pedido (expedidos)',
            formatter(produtos_pedido, {produtos_expedicao}) {
                produtos_pedido = produtos_pedido ? JSON.parse(produtos_pedido) : [];
                produtos_expedicao = produtos_expedicao ? JSON.parse(produtos_expedicao) : [];

                return <>
                    {produtos_pedido.map((p, idx) => {
                        let expedido = produtos_expedicao.find(p_exp => parseInt(p_exp.id_produto) === p.id_produto);
                        return <p className={"m-0"} key={p.id_produto}><small>{produtos[p.id_produto]?.nome}: </small>{p.quantidade} {expedido ? <>({expedido.quantidade})</> : null}</p>
                    })}
                    {produtos_expedicao.filter(p_exp => !produtos_pedido.map(p => p.id_produto).includes(parseInt(p_exp.id_produto))).map((p, idx) => {
                        return <p className={"m-0"} key={p.id_produto}><small>{produtos[p.id_produto]?.nome}: </small>0 ({p.quantidade})</p>
                    })}
                </>
            }
        }, {
            dataField: "data_criacao",
            text: 'Data',
            formatter: data => moment(data).format("DD/MM/YYYY")
        }, {
            dataField: "comentario",
            text: 'Comentário',
            headerStyle: {
                width: 200
            }
        }, {
            text: 'Estado',
            formatter: (_, pedido) => {
                let estado = pedido.data_expedido ? "expedido" : (pedido.data_rejeitado ? "recusado" : "pendente")
                return {
                    expedido: <span style={{textTransform: "uppercase", backgroundColor: "#4F8B1C40", color: "white", borderRadius: "7px", padding: "8px"}}>Expedido</span>,
                    recusado: <span style={{textTransform: "uppercase", backgroundColor: "#8B1C1C40", color: "white", borderRadius: "7px", padding: "8px"}}>Recusado</span>,
                    pendente: <span style={{textTransform: "uppercase", backgroundColor: "#FFBB22", color: "white", borderRadius: "7px", padding: "8px"}}>Pendente</span>
                }[estado];

            }
        }, {
            text: 'Ações',
            formatter: (_, pedido, reload) => {
                let estado = pedido.data_expedido ? "expedido" : (pedido.data_rejeitado ? "recusado" : "pendente")
                return {
                    expedido: <>
                        {!!pedido.pode_anular && <a
                            className="link link_acao"
                            onClick={() => {
                                confirmAlert({
                                    title: `Confirmação`,
                                    message: <>Pretende anular a expedição para <strong>{pedido.vendedor_nome}</strong>? Os cartões picados serão removidos do sistema, assim como das árvores dos colaboradores.</>,
                                    buttons: [
                                        {
                                            label: 'Sim',
                                            onClick: async () => {
                                                await post(`/cartoes/pedidos/${pedido.id}/anular_expedicao`);
                                                cogoToast.success('Expedição anulada com sucesso', {hideAfter: 5, position: 'top-right'});
                                                reload();
                                            }
                                        },
                                        {label: 'Cancelar'}
                                    ]
                                });
                            }}>Cancelar</a>}
                    </>,
                    recusado: <>
                        <a className="link link_acao"
                           onClick={() => {
                               confirmAlert({
                                   title: `Confirmação`,
                                   message: <>Pretende desmarcar o pedido de <strong>{pedido.vendedor_nome}</strong> como recusado?</>,
                                   buttons: [
                                       {
                                           label: 'Sim',
                                           onClick: async () => {
                                               await post(`/cartoes/pedidos/${pedido.id}/anular_recusar`);
                                               cogoToast.success('Marcação de recusado anulada com sucesso', {hideAfter: 5, position: 'top-right'});
                                               reload();
                                           }
                                       },
                                       {label: 'Cancelar'}
                                   ]
                               });
                           }}>Cancelar</a>
                    </>,
                    pendente: <>
                        <a className="link link_acao" onClick={() => {
                            let produtos_pedido = JSON.parse(pedido.produtos_pedido || "[]");
                            formulario.abrirFormulario("expedicao_cartoes", {
                                valoresIniciais: {
                                    pedido: pedido.id,
                                    produtos: produtos_pedido,
                                },
                                formularioSubmetido: reload
                            })
                        }
                        }
                        >Expedir</a><br/>
                        <a className="link link_acao"
                           onClick={() => {
                               confirmAlert({
                                   title: `Confirmação`,
                                   message: <>Pretende marcar o pedido de <strong>{pedido.vendedor_nome}</strong> como recusado?</>,
                                   buttons: [
                                       {
                                           label: 'Sim',
                                           onClick: async () => {
                                               await post(`/cartoes/pedidos/${pedido.id}/recusar`);
                                               cogoToast.success('Pedido marcado como recusado', {hideAfter: 5, position: 'top-right'});
                                               reload();
                                           }
                                       },
                                       {label: 'Cancelar'}
                                   ]
                               });
                           }}>Recusar</a>
                    </>
                }[estado];
            }
        }
    ];

    let filters = [
        {
            type: 'select',
            label: 'Estado',
            name: 'estado',
            default: 'todos',
            options: [
                { value: 'todos', label: 'Todos'},
                { value: 'pendente', label: 'Pendente' },
                { value: 'expedido', label: 'Expedido' },
                { value: 'recusado', label: 'Recusado' },
            ]
        }
    ]

    return <div className={"PedidosCartoes"}>
        <ContextSelecionados.Provider value={{selecionados}}>
            <TabelaPaginada
                numero_resultados={21}
                fetchData={async (filtros) => {
                    if(filtros.estado == 'todos') delete filtros.estado;
                    let dados = await get("/cartoes/pedidos/listar", filtros);
                    setPedidos(dados);
                    return dados;
                }}
                columns={columns}
                title={"Pedidos de Cartões"}
                mostrarExportacao
                filters={filters}
                newButtonShow={true}
                newButtonClick={({reload}) => {
                    formulario.abrirFormulario("pedido_cartoes", {
                        extra: {
                            preencher_quantidades: true
                        },
                        formularioSubmetido: reload
                    })
                }}
                newButtonText={"Novo pedido"}>
                <a target={"_blank"} href={fullURL("/cartoes/pedidos/" + selecionados.join(",") + "/imprimir_moradas")} className="btn outlined imprimir">
                    <i className={"fa fa-print"}/> Imprimir selecionados
                </a>
            </TabelaPaginada>
        </ContextSelecionados.Provider>
    </div>;
}

export default PedidosCartoes;