import React, {useEffect, useState} from 'react';
import {get} from "../../../../YoubizProvider";
import cogoToast from "cogo-toast";

import ProcurarColaborador from "../../../ProcurarColaborador";

import './styles.scss';

export default ({values, register, errors, extra = {}, setValue}) => {
    const [produtos, setProdutos] = useState(null);

    useEffect(() => {
        get("/cartoes/produtos/listar")
            .then(setProdutos)
            .catch(error => {
                if (error.response?.data) {
                    cogoToast.error(error.response.data.message, {
                        hideAfter: 5,
                        position: 'top-right'
                    });
                } else {
                    cogoToast.error('Erro ao carregar produtos', {
                        hideAfter: 5,
                        position: 'top-right'
                    });
                }
            });
    }, []);

    useEffect(() => {
        if (!produtos || !extra.colaborador)
            return;
        setValue("colaborador", JSON.stringify(extra.colaborador));
    }, [produtos]);

    useEffect(() => {
        if (!values.colaborador || !extra.preencher_quantidades)
            return;
        let colaborador = JSON.parse(values.colaborador);

        get("/cartoes/pedidos/ultimo_pedido/" + colaborador.id).then(data => {
            values.produtos.forEach((produto, idx) => {
                let p = JSON.parse(produto.id);
                let quantidade = data.find(d => parseInt(d.id_produto) === p.id)?.quantidade;
                let quantidades = p.quantidades.split(",").map(Number);
                if (!quantidades.includes(quantidade)) {
                    setProdutos(produtos => {
                        let copy = {...produtos};
                        quantidades.push(quantidade);
                        copy.resultados[idx].quantidades = quantidades.join(",");
                        return copy;
                    });
                }
                if (quantidade)
                    setValue("produtos[" + idx + "].quantidade", quantidade);
            });
            cogoToast.success('Quantidades preenchidas automaticamente', {hideAfter: 5, position: 'top-right'});
        });
    }, [values.colaborador]);

    return <div className={"NovaVenda"}>
        {extra.headerText && <div>
            <div className="form-group mb-1">
                <p>
                    {extra.headerText}
                </p>
            </div>
        </div>}
        {!extra.colaborador ? <ProcurarColaborador values={values} register={register} errors={errors}/> : <input
            name={"colaborador"}
            placeholder="ESCONDIDO"
            type="text"
            className="d-none"
            ref={register}/>}
        <div>
            <div>
                <div className="form-group mb-1">
                    <label>
                        Produtos
                        <span className={"text-muted mt-1 d-block"}>Escolha o número de unidades de cada produto</span>
                    </label>
                </div>
            </div>
            {produtos?.resultados.map((produto, idxProduto) => {
                return <div className={"Produto"}>
                    <input
                        name={"produtos[" + idxProduto + "].id"}
                        placeholder="ESCONDIDO"
                        type="text"
                        className="d-none"
                        value={JSON.stringify(produto)}
                        ref={register({
                            required: idxProduto === 0 ? "Por favor indique um produto" : null
                        })}/>
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group mb-0">
                                <input type="text" className="form-control form-control-sm"
                                       style={{pointerEvents: "none"}}
                                       value={produto.nome}/>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group mb-0">
                                <label data-error={errors.produtos && errors.produtos[idxProduto]?.quantidade?.message}>
                                    <select
                                        ref={register({
                                            validate: (value) => {
                                                if (['', '0'].includes(values.produtos?.[idxProduto]?.quantidade) && values.produtos?.filter(p => parseInt(p.quantidade || 0) > 0).length > 0)
                                                    return true;
                                                if (values.produtos?.[idxProduto]?.id && (!value || value <= 0))
                                                    return "Por favor indique a quantidade";
                                            },
                                        })}
                                        name={"produtos[" + idxProduto + "].quantidade"}
                                        className={"form-control" + (values.produtos && values.produtos[idxProduto]?.quantidade ? " ok" : "")}>
                                        <option value={""} disabled selected>Quantidade</option>
                                        {produto.quantidades?.split(",").filter(a => a).map(q => {
                                            return <option value={q}>{q}</option>;
                                        })}
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>;
            })}
        </div>
    </div>
}